import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

/**
 *  Matson Milestone on Port Info News & Press Page
 */
class FeaturedNews extends React.Component {
  state = {
    pageInfo: [],
    postUrl: ""
  }

  componentDidMount() {
    fetch("https://thehelm.polb.com/wp-json/wp/v2/pages?slug=news-and-press")
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ pageInfo: json }, () => secondCall());
        }
      })

    const secondCall = async () => {
      const response = await fetch(`https://thehelm.polb.com/wp-json/wp/v2/posts/?slug=${this.state.pageInfo[0].acf.featured_news_article.url.substring(25)}`);
      const json = await response.json();
      if (json.length > 0) {
        // console.log(json);
        this.setState({ postUrl: json[0].link.substring(25) });
      } else {
        console.log("Second response returned empty");
      }
    }
  }

  render() {
    const pageInfo = this.state.pageInfo[0];
    const urlPath = this.state.postUrl;

    return (
      <React.Fragment>
        {
          pageInfo &&
          <section className="featured featured--grid">
            <img src={pageInfo.acf.featured_news_article.image} alt="" className="featured__pic" />
            <div className="featured__inner">
              <h2 className="featured__heading">{pageInfo.acf.featured_news_article.title}</h2>
              <p className="intro featured__intro">{pageInfo.acf.featured_news_article.excerpt}</p>

              <Link to={`/port-info/news-and-press/${urlPath}`} className="view-more-port read-more">Read More</Link>
            </div>
          </section>
        }
      </React.Fragment>
    );
  }
}

export default FeaturedNews;